const noticeStore = {
	namespaced: true,
	state: {
		noticeList: [
			{
				id: "issuance",
				link: "/noticelist",
				date: "2023.12.11",
				title: "주주배정 유상증자 신주발행의 공고",
				pdf: false,
				content: `
                당사 정관 제13조 규정에 의해 주주배정방식의 신주발행을 결의하였기에 상법 제418조 4항에 따라 아래와 같이 주주님들께 공고합니다.

				1. 신주의 종류 : 기명식 보통주식
                2. 발행할 신주의 총수 : 132,575주
                3. 신주의 발행가액 : 1주당 5,000원 (1주당 액면가액 500원)
                4. 신주의 배정방법 : 1주당 0.25주, 주주배정방식
                5. 발행할 신주의 배정기준일 : 2023년 12월 26일
                6. 실권주의 처리 : 실권주는 발행하지 아니함
                7. 신주의 청약일 : 2023년 12월 27일(1일간)
                8. 주금납입일 : 2023년 12월 27일
                9. 주금납입처 : ㈜라온로드 경영지원팀
                10. 기타 신주 발행에 관한 절차사항은 대표이사에게 일임함.
				`,
				subContent: `
2023년 12월 11일

주식회사 라온로드
경기도 성남시 분당구 판교로 723,제비동406호,406-1호(야탑동,분당테크노파크)
대표이사 이 석 중
        `,
			},
			{
				id: "design",
				link: "/noticelist",
				date: "2023.09.01",
				title: "디자인관련 용역 공고",
				pdf: true,
				image: require("@/assets/images/notice01.png"),
				content: `
				○ 입찰서류는 자유양식으로 진행되며 용역 수행비용은 필수로 기재하여야함
				○ 입찰에 참가하고자 하는 자는 입찰관련 사항을 완전히 숙지하여야 하며, 이를 숙지하지 못한 책임은 응찰자에게 있음
				○ 제출기한 내 접수되지 않은 서류는 인정하지 않으며, 제출된 서류는 일체 반환하지 않음
				○ 공고내용 중 입찰 및 계약에 관하여 명시되지 않은 사항은 관계법령 및 당사의 해석에 따라야 함
				○ 문의사항 연락처
				- 입찰 및 과업 내용 관련 문의 : 라온로드 김성민(smkim@laonroad.com)
				`,
			},
			{
				id: "system",
				link: "/noticelist",
				date: "2023.09.01",
				title: "열관리시스템 관련 용역 공고",
				pdf: true,
				image: require("@/assets/images/notice02.png"),
				content: `
				○ 입찰서류는 자유양식으로 진행되며 용역 수행비용은 필수로 기재하여야함
				○ 입찰에 참가하고자 하는 자는 입찰관련 사항을 완전히 숙지하여야 하며, 이를 숙지하지 못한 책임은 응찰자에게 있음
				○ 제출기한 내 접수되지 않은 서류는 인정하지 않으며, 제출된 서류는 일체 반환하지 않음
				○ 공고내용 중 입찰 및 계약에 관하여 명시되지 않은 사항은 관계법령 및 당사의 해석에 따라야 함
				○ 문의사항 연락처
				- 입찰 및 과업 내용 관련 문의 : 라온로드 김성민(smkim@laonroad.com)
				`,
			},
			{
				id: "plan",
				link: "/noticelist",
				date: "2023.09.01",
				title: "전장설계 관련 용역 공고",
				pdf: true,
				image: require("@/assets/images/notice03.png"),
				content: `
				○ 입찰서류는 자유양식으로 진행되며 용역 수행비용은 필수로 기재하여야함
				○ 입찰에 참가하고자 하는 자는 입찰관련 사항을 완전히 숙지하여야 하며, 이를 숙지하지 못한 책임은 응찰자에게 있음
				○ 제출기한 내 접수되지 않은 서류는 인정하지 않으며, 제출된 서류는 일체 반환하지 않음
				○ 공고내용 중 입찰 및 계약에 관하여 명시되지 않은 사항은 관계법령 및 당사의 해석에 따라야 함
				○ 문의사항 연락처
				- 입찰 및 과업 내용 관련 문의 : 라온로드 김성민(smkim@laonroad.com)
				`,
			},
		],
	},
	getters: {
		GetNumber: (state) => {
			return state.noticelist.length;
		},
	},
};

export default noticeStore;
